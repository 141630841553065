<template>
  <div>
    <div style="height: 30px"></div>
    <h1>망고네 피아노</h1>
    <div style="height: 30px"></div>
    <div class="piano">
      <div class="key white" @click="playNote(261.63)"></div>
      <div class="key black" @click="playNote(277.18)"></div>
      <div class="key white" @click="playNote(293.66)"></div>
      <div class="key black" @click="playNote(311.13)"></div>

      <div class="key white" @click="playNote(329.63)"></div>
      <div class="key white" @click="playNote(349.23)"></div>

      <div class="key black" @click="playNote(369.99)"></div>
      <div class="key white" @click="playNote(392.0)"></div>
      <div class="key black" @click="playNote(415.3)"></div>
      <div class="key white" @click="playNote(440.0)"></div>
      <div class="key black" @click="playNote(466.16)"></div>

      <div class="key white" @click="playNote(493.88)"></div>
      <div class="key white" @click="playNote(523.25)"></div>
      <div class="key black" @click="playNote(554.37)"></div>
      <div class="key white" @click="playNote(587.33)"></div>
      <div class="key black" @click="playNote(622.25)"></div>
      <div class="key white" @click="playNote(659.25)"></div>
    </div>
  </div>
</template>

<script>
import AudioEngine from "@/scripts/AudioEngine";

export default {
  components: {},
  data() {
    return {
      frequencies: {
        도: 261.63,
        레: 293.66,
        미: 329.63,
        파: 349.23,
        솔: 392.0,
        라: 440.0,
        시: 493.88,
        도2: 523.25,
        레2: 587.33,
        미2: 659.25,
        파2: 698.46,
        솔2: 783.99,
        // 라2: 880.0,
      },
    };
  },
  methods: {
    playNote(frequency) {
      AudioEngine.playFrequency(frequency);
    },
    stopNote() {
      // 필요한 경우 여기에 소리를 멈추는 코드를 추가합니다
    },
  },
};
</script>

<style scoped>
.test-button {
  width: 50px;
  height: 50px;
}

.piano {
  padding: 50px 10px 0 10px;
  background-color: #363c4a;
  border-radius: 15px 15px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.key {
  width: 60px;
  cursor: pointer;
  transition: all 0.3s;
}
.white {
  height: 220px;
  background-color: white;
  border: 5px solid #363c4a;
  border-bottom: 5px solid #dfd3d3;
  z-index: 1;
}
.white:active {
  border-bottom: none;
  background-color: #dfd3d3;
}
.black {
  width: 30px;
  height: 130px;
  background-color: #363c4a;
  z-index: 2;
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 5px solid #5d5e67;
}
.black:active {
  border-bottom: none;
}
</style>
