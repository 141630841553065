class AudioEngine {
  constructor() {
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
  }

  playFrequency(frequency) {
    //const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const audioContext = this.audioContext;

    const oscillator = audioContext.createOscillator();
    const gainNode = audioContext.createGain();

    oscillator.frequency.value = frequency;
    oscillator.type = "sine";

    oscillator.connect(gainNode);
    gainNode.connect(audioContext.destination);

    gainNode.gain.setValueAtTime(0, audioContext.currentTime);
    gainNode.gain.value = 1;
    gainNode.gain.linearRampToValueAtTime(1, audioContext.currentTime + 0.01); // 공격 시간
    gainNode.gain.linearRampToValueAtTime(0, audioContext.currentTime + 0.25); // 감쇠 시간
    oscillator.start();
    oscillator.stop(audioContext.currentTime + 0.3); // 1초 동안 소리를 낸다
  }
}

export default new AudioEngine();
